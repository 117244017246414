<template>
  <BlokkliProvider v-slot="{ entity }" v-bind="blokkliProps" :entity="props">
    <div class="page">
      <section v-if="showTitle" class="section">
        <div class="container narrow">
          <h1 v-blokkli-editable:title class="title is-h1 is-page-title">
            {{ entity?.title || title }}
          </h1>
          <div
            v-blokkli-editable:field_teaser_text="{ type: 'frame' }"
            class="intro"
            v-html="lead"
          />
          <hr v-if="lead" />
        </div>
      </section>
      <div>
        <BlokkliField
          :list="paragraphs"
          name="field_content"
          class="elements"
        />
      </div>
    </div>
  </BlokkliProvider>
</template>

<script lang="ts" setup>
import type { NodePageFragment } from '#graphql-operations'

const props = defineProps<NodePageFragment>()

const showTitle = computed(() => {
  return !props.hideTitle
})
</script>

<style lang="scss" scoped>
.intro {
  font-size: $size-5;
  font-weight: bold;
  color: $grey;
}

.section {
  hr:last-child {
    margin-bottom: 0;
  }
}

.section.bottom {
  margin-bottom: -2 * 3.5rem;
}
</style>
